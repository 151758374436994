import { Box, Heading } from "native-base";

const ListCreatePage = () => {
  return (
    <Box>
      <Heading>Nova lista</Heading>
    </Box>
  );
};

export default ListCreatePage;
